@font-face {
  font-family: 'OpenSans-SemiBoldItalic';
  src: local('OpenSans-SemiBoldItalic'), url(./assetts/fonts/OpenSans/OpenSans-SemiBoldItalic.ttf) format('truetype');
}

.App {
  background-image: url('./assetts/IMG_9657.jpg');
  background-repeat: repeat;
}

.appHeader {
  text-align: center;  
  /* background-color: rgba(252, 186, 10, .5); */
  padding: 1%;
  color: #2a1e18;
  /* border-radius: 77%; */
  font-size: calc(10px + 2vmin);
  font-family: 'OpenSans-SemiBoldItalic';
  text-shadow: 3px 0 0 cornsilk;
}

.audioPlayer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bioContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrappedBio {
  border-color: #2a1e18;
  border-width: 100 100;
  border-style: solid;
  background-color: #FCBA0A;
  font-family: 'OpenSans';
  color: #2a1e18;
  padding: 1%;
}

.headShot {
  opacity: 0.8;
}

.albumCover {
  opacity: 0.8;
}

